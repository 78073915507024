<template>
  <f7-link panel-toggle="#leftPanel">
    <f7-badge v-if="NotificationCount > 0" class="badge-notification" color="blue">{{ NotificationCount }}</f7-badge>
    <font-awesome-icon :icon="['far', 'bars']" fixed-width />
  </f7-link>
</template>

<script>
import { defineComponent, computed } from "vue";
import { useStore } from "@/store";

export default defineComponent({
  name: "LeftPanelNavigationComponent",
  components: {},
  props: {},
  setup(props) {
    const store = useStore();
    const NotificationCount = computed(() => store.getters["chat/getData"]?.NotificationCount);

    return {
      NotificationCount,
    };
  },
});
</script>

<style scoped>
.badge-notification {
  position: absolute;
  top: 4px;
  right: 4px;
}
</style>
